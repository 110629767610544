import useSWR, { type SWRConfiguration } from 'swr'
import { type UserSubscritionStatus, type Event, type User, type LaravelNotification } from '@/types'
import { destroy, get, patch, post } from './fetcher'
import { type AxiosError } from 'axios'
import useSWRCursorPaginate from './useSWRCursorPaginate'
import { type SWRInfiniteConfiguration } from 'swr/infinite'

export const useMe = (options?: SWRConfiguration) => {
  return useSWR<User, AxiosError>('/api/v2/users/me', get, options)
}

export const useReservedEvents = (options?: SWRConfiguration) => {
  return useSWR<Event[], AxiosError>('/api/v2/users/me/reserved_events', get, options)
}

export const useUsers = (options?: SWRInfiniteConfiguration) => {
  return useSWRCursorPaginate<User>('/api/v2/users', options)
}

/**
 * @param id - userが任意に設定できるmy_id（ユーザーに自動で割り当てられたIDではない）
 * @param options - SWRConfiguration
 * @returns - SWRResponse
 */
export const useUser = (id: string, options?: SWRConfiguration) => {
  return useSWR<User, AxiosError>(`/api/v2/users/${id}`, get, options)
}

export const useMeVipStatus = (options?: SWRConfiguration) => {
  return useSWR<UserSubscritionStatus, AxiosError>('/api/v2/users/me/subscriptions/vip', get, options)
}

export const useMeNotifications = (options?: SWRConfiguration) => {
  return useSWRCursorPaginate<LaravelNotification>('/api/v2/users/me/notifications', options)
}

type UserFetchParams = {
  my_id: string
  email: string
  current_password: string
  password: string
  password_confirmation: string
  name: string
  age: number
  gendar: string
  live: string
  nationality: string
  learn_language: string
  // learn_language_level: number
  ja_language_level: number
  en_language_level: number
  want_speed: number
  bio?: string | null | undefined
  bio_url?: string  | null | undefined
  hobbies?: Array<string | undefined> | undefined
}

export const updateMe = async (params: Partial<UserFetchParams>) => {
  const data = await patch<User>(`/api/v2/users/me`, params)
  return data
}

export const updateMeIcon = async (file: File) => {
  const formData = new FormData()
  formData.append('upload_file', file)
  const data = await post<Event>(`/api/v2/users/me/icon`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const removeMe = async () => {
  await destroy('/api/v2/users/me')
}

export const useUsersByFilter = (filter: string | undefined, options?: SWRInfiniteConfiguration) => {
  return useSWR<User[], AxiosError>(filter ? `/api/v2/users?filter=${filter}` : null, get, options)
}

export const setBanUser = async (id: string, ban: boolean) => {
  return await patch<User>(`/api/v2/users/${id}/banuser?ban=${ban}`)
}

export const setAdminUser = async (id: string, admin: boolean) => {
  return await patch<User>(`/api/v2/users/${id}/adminuser?admin=${admin}`)
}

export const setCountBorderNow = async (id: string) => {
  return await patch<User>(`/api/v2/users/${id}/absence_events/count_border/now`)
}

export const deleteNotification = async (id: string) => {
  return await destroy(`/api/v2/notifications/${id}`)
}
