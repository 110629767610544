import { createTheme } from '@mantine/core'

export const theme = createTheme({
  colors: {
    green: [
      '#e6fff5',
      '#d0ffeb',
      '#9ffed6',
      '#6bfebf',
      '#46feac',
      '#32fea0',
      '#00ca75', // primary
      '#07bf72',
      '#06b46b',
      '#03a25b'
    ]
  },
  primaryColor: 'green'
})
