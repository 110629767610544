// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports

// mantine css
import '@mantine/core/styles.css'

// mantine notifications css
import '@mantine/notifications/styles.css'

// mantine-datatable css
import 'mantine-datatable/styles.layer.css'
import './src/css/layout.css'

// mantine carousel css
import '@mantine/carousel/styles.css'

// mantine-dates css
import '@mantine/dates/styles.css'

import React from 'react'
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import { theme } from './src/theme'
import { GatsbyBrowser } from 'gatsby'
import AccountInfomationProvider from './src/Provider/AccountInfomationProvider/AccountInfomationProvider'
import { DatesProvider } from '@mantine/dates'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element
}) => {
  return (
    <AccountInfomationProvider>
      <MantineProvider theme={theme}>
        <DatesProvider settings={{}}>
          <Notifications />
          <ModalsProvider>{element}</ModalsProvider>
        </DatesProvider>
      </MantineProvider>
    </AccountInfomationProvider>
  )
}
